import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { graphql } from "gatsby";
import { TestimonialCard } from "../components/sections/Testimonials";
import { UniversalLink } from "../components/global";
import GallerySlider from "../components/molecules/GallerySlider";
import Share from "../components/sections/Share";

const TestimonialsPage = ({ data }) => {
  const testimonials = data.allFile.edges;
  return (
    <Layout>
      <SEO title={"Testimonials"} />
      <section className={"section container mx-auto"}>
        <h1 className={"sr-only"}>Testimonials</h1>
        <p className={"h1 block text-center"}>
          A few reasons why we
          <span role={"img"} aria-label={"heart-emoji"}>
            {" "}
            ❤️{" "}
          </span>
          BD...
        </p>
        <div className={"flex flex-wrap items-start"}>
          {testimonials.map(({ node }) => (
            <div className={"w-full md:w-1/2"} key={node.id}>
              <TestimonialCard
                message={node.childMarkdownRemark.frontmatter.message}
                photo={node.childMarkdownRemark.frontmatter.photo}
                submittedBy={node.childMarkdownRemark.frontmatter.submitted_by}
                submittedVia={
                  node.childMarkdownRemark.frontmatter.submitted_via
                }
              />
            </div>
          ))}
        </div>
      </section>
      <section>
        <h2 className={"mt-16 h2 text-center"}>
          A few photos
          <span role={"img"} aria-label={"camera-emoji"}>
            {" "}
            📷️{" "}
          </span>
        </h2>
        <h3 className={"text-center mt-4"}>
          <UniversalLink url={"/gallery"} className={"border-b border-primary"}>
            View full gallery
          </UniversalLink>
        </h3>
        <div className={"max-w-3xl mx-auto mt-8"}>
          <GallerySlider />
        </div>
        <section className={"mt-16"}>
          <Share />
        </section>
      </section>
      <div className={"pb-8"} />
    </Layout>
  );
};

export const testimonialsPageQuery = graphql`
  query TestimonialsPageQuery {
    allFile(filter: { relativeDirectory: { eq: "testimonials" } }) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              title
              submitted_via
              submitted_by
              message
              photo {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TestimonialsPage;
